import { t } from "@/i18n-js/instance";
import { classNames } from "@circle-react/helpers/twMergeWithCN";

interface DefaultTermsLabelProps {
  showCreatorTerms?: boolean;
  locale?: "pt" | "en";
  communityTermsUrl?: string;
  communityPrivacyUrl?: string;
  variant: "paywallCheckout" | "newPaywallCheckout" | "default";
}

const defaultStyle = {
  spanWrapperClassName: "text-light text-xs",
  firstLinkClassName: "text-default hover:text-light font-bold",
  secondLinkClassName: "text-default hover:text-light font-bold",
};

const variants = {
  default: defaultStyle,
  paywallCheckout: defaultStyle,
  newPaywallCheckout: {
    spanWrapperClassName:
      "text-xs font-normal leading-5 tracking-tight normal-case text-primary",
    firstLinkClassName: "text-dark hover:underline hover:text-dark font-bold",
    secondLinkClassName: "text-dark hover:underline hover:text-dark font-bold",
  },
};

const CIRCLE_BASE_PATH = "https://circle.so";

const createUrl = (path: string, locale?: "pt" | "en") => {
  const localPrefix = locale === "pt" ? "/br" : "";
  return `${CIRCLE_BASE_PATH}${localPrefix}/${path}`;
};

export const DefaultTermsLabel = ({
  showCreatorTerms,
  locale,
  communityTermsUrl,
  communityPrivacyUrl,
  variant,
}: DefaultTermsLabelProps) => {
  const localeScope = "authentication_app.signup.form.terms_and_privacy";

  const variantStyle = variants[variant] || variants["default"];

  const termsUrl = showCreatorTerms
    ? createUrl("creator-terms", locale)
    : communityTermsUrl;
  const privacyUrl = showCreatorTerms
    ? createUrl("privacy", locale)
    : communityPrivacyUrl;

  return (
    <span className={classNames(variantStyle.spanWrapperClassName)}>
      {t(`${localeScope}.prefix`)}{" "}
      <a
        className={classNames(variantStyle.firstLinkClassName)}
        href={termsUrl}
        target="_blank"
        rel="noreferrer"
      >
        {t([
          localeScope,
          showCreatorTerms ? "creator_terms_of_service" : "terms_of_service",
        ])}
      </a>{" "}
      {t(`${localeScope}.and`)}{" "}
      <a
        className={classNames(variantStyle.secondLinkClassName)}
        href={privacyUrl}
        target="_blank"
        rel="noreferrer"
      >
        {t([localeScope, "privacy_policy"])}
      </a>
    </span>
  );
};
