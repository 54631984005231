import { useCurrentCommunityContext } from "@circle-react/apps/AuthenticationApp";
import { usePaywallCheckoutContext } from "@circle-react/contexts/Paywalls/paywallCheckoutContext";
import { DefaultTermsLabel } from "./DefaultTermsLabel";

interface AcceptTermsLabelProps {
  variant?: "paywallCheckout" | "newPaywallCheckout" | "default";
}

export const AcceptTermsLabel = ({
  variant = "default",
}: AcceptTermsLabelProps) => {
  const checkoutContext = usePaywallCheckoutContext();
  const currentCommunityContext = useCurrentCommunityContext();

  const { currentCommunity } =
    variant === "paywallCheckout" ? checkoutContext : currentCommunityContext;

  if (!currentCommunity) {
    return <DefaultTermsLabel showCreatorTerms variant={variant} />;
  }

  const { locale, terms_url, privacy_url } = currentCommunity;
  return (
    <DefaultTermsLabel
      showCreatorTerms={false}
      locale={locale}
      communityTermsUrl={terms_url}
      communityPrivacyUrl={privacy_url}
      variant={variant}
    />
  );
};
